/* This SCSS file is responsible for all common styles for configurable tables.
 */
@import "globals";

/* stylelint-disable-next-line selector-id-pattern */
.full-screen-display:has(#controlMethodsPage, #supplierListPage, #userListPage) {
  height: 90% !important;
}

/* stylelint-disable-next-line selector-id-pattern */
#controlMethodsPage,
#supplierListPage,
#userListPage {
  /* stylelint-disable-next-line selector-id-pattern */
  #bodyDiv {
    background-color: unset;
  }

  .nav-bar {
    .nav-bar-item-selected {
      background-color: $brand-white;
    }
  }
}

/* stylelint-disable-next-line selector-id-pattern */
#productPage {
  .nav-bar {
    .nav-bar-item-selected {
      background-color: $brand-white;
    }
  }
}

/* stylelint-disable-next-line selector-id-pattern */
#processExplorerPage ~ #columnSelectionDiv {
  height: calc(100vh - 144px);
}

/* stylelint-disable-next-line selector-id-pattern */
#library-list-page ~ #columnSelectionDiv {
  height: calc(100vh - 108px);
}

/* stylelint-disable-next-line selector-id-pattern */
#configurableTableSplitPanel {
  margin-top: 0 !important;
}

.custom-scrollbar {
  position: fixed;
  bottom: 0;
  height: 25px;
  overflow-x: scroll;
  z-index: 2;
}

.custom-vertical-scrollbar {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 25px;
  overflow-y: scroll;
  z-index: 2;
}

/* stylelint-disable-next-line selector-id-pattern */
#infoCM {
  display: inline-flex;
  align-items: center;
}

.dropdown-wrapper {
  position: absolute;
  z-index: 100;
  display: inline-block;
}

.shown {
  cursor: pointer;
  display: inline-block;
}

.table-options-menu {
  cursor: pointer;
}

.k-child-animation-container:has(.k-grid-columnmenu-popup) {
  max-height: 100% !important;

  .k-grid-columnmenu-popup {
    padding: 0.5rem 0 !important;
    border-radius: 4px;
    border: 1px solid $brand-primary-color;
    box-shadow:
      0 1px 18px 0 rgba(0, 0, 0, 0.12),
      0 6px 10px 0 rgba(0, 0, 0, 0.14),
      0 3px 5px 0 rgba(0, 0, 0, 0.2);
    max-height: 100% !important;
    overflow-y: auto !important;

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
      border-radius: 10px;
    }
  }
}
